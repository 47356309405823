import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import api from '../../services/api';
import Modal from '../../components/modal';

interface SignInFormData {
  password: string;
  passwordConfirm: string;
}

interface ParamTypes {
  [key: string]: string;
  token: string;
}

const ResetPassword: React.FC = () => {
  const { token } = useParams<ParamTypes>();
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [helperTextPassword, setHelperTextPassword] = useState<string>('');
  const [mailUser, setMailUser] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    api
      .post('/users/checkToken', { token })
      .then(response => {
        if (response.status === 200) {
          setMailUser(response.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  });

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const user: SignInFormData = {
        password: data?.get('password')?.toString() ?? '',
        passwordConfirm: data?.get('passwordConfirm')?.toString() ?? '',
      };

      const isConfimed = user.password === user.passwordConfirm;
      if (isConfimed) {
        api
          .put('/users/resetPassword', {
            token,
            email: mailUser,
            password: user.password,
          })
          .then(response => {
            console.log(response);
          });
        setSnackBarOpen(true);
        setTimeout(() => {
          navigate('/login');
        }, 1500);
      } else {
        setErrorPassword(true);
        setHelperTextPassword('Incorrect password combination');
      }
    },
    [mailUser, navigate, token],
  );

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ): any => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  return (
    <>
      {mailUser ? (
        <ThemeProvider theme={createTheme()}>
          <Container
            component="main"
            maxWidth="xs"
            style={{
              border: '1px solid #DAE2ED',
              padding: 22,
              borderRadius: 12,
              marginTop: 25,
            }}
          >
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}></Avatar> */}
              <Typography component="h1" variant="h5">
                Reset account password
              </Typography>
              <br />
              <span>Enter a new password for {mailUser}</span>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  error={errorPassword}
                  helperText={helperTextPassword}
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="passwordConfirm"
                  label="Confirm password"
                  error={errorPassword}
                  helperText={helperTextPassword}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  // onClick={handleSubmit}
                >
                  Reset password
                </Button>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      ) : (
        <Modal
          content={'invalidToken'}
          invalidTokenProp={{ open: true }}
        ></Modal>
      )}
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Password reset successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResetPassword;
