import * as React from 'react';
import { useState, useEffect } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { Content, Container } from './styles';
import GridMui from '../../components/gridMui';
import api from '../../services/api';
import Loading from '../../components/loading';

const Users: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [column, setColumn] = useState<any[]>([]);
  useEffect(() => {
    api.get('/users').then((response: any) => {
      setData(response.data);
    });
  }, []);

  const RenderValue = (
    props: GridRenderCellParams<string[]>,
  ): React.ReactNode => {
    const { hasFocus, value } = props;
    const buttonElement = React.useRef<HTMLButtonElement | null>(null);
    const rippleRef = React.useRef<TouchRippleActions | null>(null);
    React.useLayoutEffect(() => {
      if (hasFocus) {
        const input = buttonElement.current?.querySelector('input');
        input?.focus();
      } else if (rippleRef.current) {
        rippleRef.current.stop({} as any);
      }
    }, [hasFocus]);
    const tags = value?.map((v: any, index: number) => {
      return (
        <span
          key={index}
          style={{
            border: '1px solid black',
            borderRadius: '3px',
            marginRight: '2px',
            padding: '1px 4px',
          }}
        >
          {v}
        </span>
      );
    });

    return (
      <div
        style={{
          padding: '1px',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {tags}
      </div>
    );
  };

  useEffect(() => {
    if (data.length > 0) {
      const columns: GridColDef<(typeof data)[number]>[] = [
        // { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'name',
          headerName: 'Name',
          flex: 0.7,
          editable: false,
        },
        {
          field: 'email',
          headerName: 'Email',
          flex: 1,
          editable: false,
        },

        {
          field: 'usergroup',
          headerName: 'UserGroup',
          flex: 1,
          editable: false,
          renderCell: RenderValue,
        },

        {
          field: 'company',
          headerName: 'Company',
          flex: 1,
          editable: false,
          renderCell: RenderValue,
        },

        {
          field: 'dashboards',
          headerName: 'Dashboards',
          flex: 1,
          editable: false,
          renderCell: RenderValue,
        },

        {
          field: 'widgets',
          headerName: 'Widgets',
          flex: 1,
          editable: false,
          renderCell: RenderValue,
        },

        {
          field: 'roles',
          headerName: 'Roles',
          flex: 1,
          editable: false,
          renderCell: RenderValue,
        },

        // {
        //   field: 'fullName',
        //   headerName: 'Test',
        //   description: 'This column has a value getter and is not sortable.',
        //   sortable: false,
        //   width: 160,
        //   valueGetter: (value, row) => `${row.name || ''} ${row.email || ''}`,
        // },
      ];

      setColumn(columns);
    }
  }, [data]);
  return (
    <Container>
      <h1 style={{ color: 'white' }}>Users</h1>
      <Content>
        {data.length > 0 ? (
          <GridMui columns={column} rows={data} />
        ) : (
          <Loading />
        )}
      </Content>
    </Container>
  );
};

export default Users;
