import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import SupersetDashboard from '../../components/superset-dashboard/superset-dashboard';
import {
  DashboardBodyBox,
  DashboardContainer,
  DashboardHeaderBox,
} from './styles';
import api from '../../services/api';
import Loading from '../../components/loading';

const DashboardPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dashIds, setDashIds] = useState<any>({});
  const [idToUuidMap, setIdToUuidMap] = useState<Map<string, number>>();
  const [dashboardOptions, setDashboardOptions] = useState([
    { label: 'select a dashboard...', value: '' },
  ]);

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/superset/getDashboards`)
      .then(response => {
        setIsLoading(false);
        const map = new Map();
        const dropdownDashboardList = response.data.map((dash: any) => {
          map.set(dash.uuid, dash.id);
          return {
            label: `${dash.dashboard_title}`,
            value: dash.uuid,
          };
        });
        setIdToUuidMap(map);
        setDashboardOptions(dropdownDashboardList);
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const handleChangeDashboard = (value: any): void => {
    const id = idToUuidMap?.get(value);
    const uuid = value;
    setDashIds({ uuid, id });
  };

  return (
    <>
      {isLoading ? (
        <DashboardContainer>
          <Loading />
        </DashboardContainer>
      ) : (
        <DashboardHeaderBox id="dashboard-header-box">
          <Select
            style={{ width: '25%', height: '100%' }}
            defaultValue={['select a dashboard...']}
            onChange={handleChangeDashboard}
            optionLabelProp="label"
            options={dashboardOptions}
          />
        </DashboardHeaderBox>
      )}
      <DashboardBodyBox id="dashboard-body-box">
        <SupersetDashboard dashIds={dashIds}></SupersetDashboard>
      </DashboardBodyBox>
    </>
  );
};

export default DashboardPage;
