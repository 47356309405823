import styled, { css } from 'styled-components';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

// export const CardContainer = styled('div')(() => ({
//   display: 'flex',
//   flex: 1,
//   width: '20%!important',
//   maxWidth: '20%!important',
//   alignItems: 'center',
//   justifyContent: 'center',
//   flexWrap: 'nowrap',
// }));

export const CardContainer = styled.div`
  display: flex;
  align-items: stretch !important;
  justify-content: center;
`;

export const CardStyled = styled(Card)`
  display: flex;
  flex: 1;
  height: 0;
  width: 100px;
  justify-content: center;
  align-items: center;

  ${css`
    background-color: rgba(200, 200, 200, 0.1) !important;
  `}

  margin-right: '30px!important';
  border-radius: 25px !important;
  margin: 25px;
  padding: 32px;
  flex-direction: column;
  margin: 0;
  box-sizing: border-box;
  box-shadow: 0 0 3px grey !important ;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    box-shadow: 4px 6px 16px grey !important ;
    background-color: #f8f8f2;
  }
`;

export const CardHeaderStyled = styled(CardHeader)`
  padding: 0 !important;

  span {
    display: block;
    font-size: 0.8rem;
    word-wrap: break-word !important;
    text-align: center !important;
  }
`;

export const CardContentStyled = styled(CardContent)`
  padding: 0 !important;
`;
