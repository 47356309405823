import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/authContext';
import { Widget } from '../UserGroupsPage';
import { Cards, HeaderWilkommen } from './styles';
import HomePageCard from '../../components/cards/homePageCard';
import RadialMenuReact from '../../components/radialMenu';

interface User {
  id: string;
  name: string;
  email: string;
  urlLogo: string;
  mainColor: string;
  companyId: number;
  userGroupName: string;
  roles: number[];
  dashboards: number[];
  videoUrl: string;
  widgets: Widget[];
}

interface Props {
  widgets?: Widget[];
}

const InitialPage: React.FC<Props> = ({ widgets = undefined }) => {
  const [userLocal, setUserLocal] = useState<User>();
  const [selectedWidget, setSelectedWidget] = useState<Widget>();
  const [widgetsList, setWidgetsList] = useState<Widget[]>([]);
  const { user } = useAuth();

  const authContext = useAuth();

  useEffect(() => {
    if (widgets) {
      // PREVIW FROM USERGROUPS
      authContext.getUserGroups(user).then(userFromDatabase => {
        setWidgetsList(widgets ?? []);
        setUserLocal(userFromDatabase);
      });
    } else {
      // HOME PAGE
      authContext.getUserGroups(user).then(userFromDatabase => {
        setWidgetsList(user.widgets);
        setUserLocal(userFromDatabase);
      });
    }
  }, [authContext, user, widgets]);

  return (
    <>
      {userLocal && (
        <HeaderWilkommen>
          <>{userLocal && <span>{`Willkommen ${userLocal?.name}!`}</span>}</>
        </HeaderWilkommen>
      )}
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <RadialMenuReact
          setSelectedWidget={setSelectedWidget}
          widgets={widgetsList}
        ></RadialMenuReact>
        {selectedWidget && (
          <Cards id="cards">
            <HomePageCard widget={selectedWidget}></HomePageCard>
          </Cards>
        )}
      </div>
    </>
  );
};

export default InitialPage;
