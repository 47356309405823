import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useCallback, useEffect, useState } from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import { CardsContent, Painel, SideNavigation } from './styles';
import { Company, Dashboard } from '../CompaniesPage';
import api from '../../services/api';
import UserGroupCard from '../../components/cards/usergroupCard';
import { OptionsBar } from '../CompaniesPage/styles';
import Modal from '../../components/modal';
import Loading from '../../components/loading';
import { useAuth } from '../../context/authContext';
import { useLogger } from '../../context/logContext';

// LOUCURA COM DOIDERA!
export interface Widget {
  widgetName: string;
  widgetUrlImage: string;
  description: string;
  widgetUrl: string;
  type: string;
}

export interface User {
  username: string;
  email: string;
}

export interface Role {
  roleId: number;
  name: string;
}

export interface UserGroup {
  userGroupId: number;
  name: string;
  companyId: number;
  dashboards: number[];
  roles: number[];
  users: string[];
  widgets: string[];
  moreInformation: string[];
}

const rolesAllowedInThisPage = [2];

const UserGroupsPage: React.FC = () => {
  const authContext = useAuth();
  const { logger } = useLogger();
  const navigate = useNavigate();
  const [companiesLine, setCompaniesLine] = useState<any>();
  const [userGroupsPanel, setUserGroupsPanel] = useState<any>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [open, setOpen] = useState(false);
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [filteredUserGroups, setFilteredUserGroups] = useState<UserGroup[]>([]);
  const [inputSearchValue, setInputSearchValue] = useState<string>();
  const [companyFilter, setCompanyFilter] = useState<number>();
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);

  const [isloading, setIsLoading] = useState<boolean>(true);

  const countUser = useCallback(
    (userEmail: string) => {
      let count = 0;
      userGroups.forEach((usergroup: UserGroup) => {
        if (
          usergroup.users
            .map((user: any) => {
              return user.email;
            })
            .includes(userEmail)
        ) {
          count += 1;
        }
      });
      return count;
    },
    [userGroups],
  );

  const handleFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputSearchValue(event.target.value.toLowerCase());
    },
    [],
  );

  const getCompanies = useCallback(() => {
    api
      .get('/companies')
      .then((response: any) => {
        setCompanies(
          response.data.map((company: Company) => {
            return {
              ...company,
            };
          }),
        );
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const getRoles = useCallback(() => {
    api
      .get('/roles')
      .then(response => {
        setRoles(response.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const getUserGroups = useCallback(() => {
    setIsLoading(true);
    api
      .get('/usergroups')
      .then((response: any) => {
        setIsLoading(false);
        setUserGroups(response.data?.usergroups);
        setFilteredUserGroups(response.data?.usergroups);

        setDashboards(
          response.data?.dashboardList.map((dash: any) => {
            return {
              dashboardName: dash.dashboard_title,
              dashboardId: dash.id,
            };
          }),
        );
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getCompanies();
    getRoles();
    getUserGroups();
  }, [getCompanies, getRoles, getUserGroups]);

  useEffect(() => {
    let filtered = [...userGroups];
    if (companyFilter) {
      filtered = filtered.filter(
        (usergroup: UserGroup) => usergroup.companyId === companyFilter,
      );
    }
    if (inputSearchValue) {
      const inputValeuTam = inputSearchValue.length;
      filtered = filtered.filter((usergroup: UserGroup) => {
        const nFirstCharacters = usergroup.name.slice(0, inputValeuTam);
        return nFirstCharacters.toLowerCase() === inputSearchValue;
      });
    }

    setFilteredUserGroups(filtered);
  }, [companyFilter, inputSearchValue, userGroups]);

  const handleClickOnCompanyLeftSideLine = useCallback((companyId: number) => {
    setCompanyFilter(companyId);
  }, []);

  const handleSaveEdit = useCallback(
    (usergroupToEdit: any) => {
      api
        .put(`/usergroups/${usergroupToEdit.userGroupId}`, usergroupToEdit)
        .then(() => {
          getUserGroups();
        })
        .catch((error: any) => {
          console.log('err', error);
          logger('error', 'Edit UserGroup', `${error.code} - ${error.message}`);
        });
    },
    [getUserGroups, logger],
  );

  const handleSaveSettings = useCallback(
    (usergroupToEdit: any) => {
      api
        .put(`/usergroups/${usergroupToEdit.userGroupId}`, usergroupToEdit)
        .then(() => {
          getUserGroups();
        })
        .catch((err: any) => {
          console.log(err);
        });
    },
    [getUserGroups],
  );

  const handleDelete = useCallback(
    (obj: any) => {
      api
        .delete(`/usergroups/${obj.userGroup.userGroupId}`)
        .then(() => {
          getUserGroups();
        })
        .catch(error => {
          console.log('err', error);
          logger(
            'error',
            'Delete usergroups',
            `${error.code} - ${error.message}`,
          );
        });
      obj.handleCloseOptions();
    },
    [getUserGroups, logger],
  );

  const handleAdd = useCallback(
    (newUserGroup: UserGroup) => {
      api
        .post('/usergroups', newUserGroup)
        .then(() => {
          getUserGroups();
        })
        .catch(error => {
          console.log('err', error);
          logger(
            'error',
            'create new usergroup',
            `${error.code} - ${error.message}`,
          );
        });
    },
    [getUserGroups, logger],
  );

  useEffect(() => {
    setCompaniesLine(
      companies.map((company: Company, index: number) => {
        return (
          <div key={index}>
            <div style={{ height: '25px' }}></div>
            <Button
              key={index}
              style={{
                left: '24px',
                height: '60px',
                borderBottomRightRadius: '50px',
                borderTopRightRadius: '50px',
              }}
              variant="contained"
              onClick={() =>
                handleClickOnCompanyLeftSideLine(company.companyId)
              }
            >
              {company.name}
            </Button>
          </div>
        );
      }),
    );
    setUserGroupsPanel(
      filteredUserGroups.map((userGroup: UserGroup, index: number) => {
        return (
          <div style={{ marginTop: '30px', height: '25%' }} key={index}>
            <UserGroupCard
              key={index}
              userGroup={userGroup}
              handleDelete={handleDelete}
              handleSaveEdit={handleSaveEdit}
              handleSaveSettings={handleSaveSettings}
              dashboardListForm={dashboards}
              rolesListForm={roles}
              companyListForm={companies}
              countUser={countUser}
            ></UserGroupCard>
          </div>
        );
      }),
    );
  }, [
    companies,
    countUser,
    dashboards,
    filteredUserGroups,
    handleClickOnCompanyLeftSideLine,
    handleDelete,
    handleSaveEdit,
    handleSaveSettings,
    roles,
  ]);

  const handleSubmit = (): void => {
    setOpen(true);
  };

  useEffect(() => {
    let userAllowed = false;
    authContext.user.roles?.forEach(role => {
      const found = rolesAllowedInThisPage.find(roleA => roleA === role);
      if (found) {
        userAllowed = true;
      }
    });

    if (!userAllowed) {
      navigate('/');
    }
  }, [authContext.user, navigate]);

  return (
    <>
      <Painel id="panel">
        <OptionsBar id="options">
          <div id="title">
            <h1 style={{ color: 'white' }}>User groups</h1>
          </div>

          <div
            id="searchBar"
            style={{ width: '50%', alignSelf: 'center', paddingTop: '10px' }}
          >
            <TextField
              id="outlined-basic"
              label="Search..."
              onChange={handleFilter}
              variant="outlined"
              style={{ width: '80%' }}
            />
          </div>

          <div
            id="buttonAdd"
            style={{ alignSelf: 'center', paddingTop: '10px' }}
          >
            <Fab color="primary" aria-label="add" onClick={handleSubmit}>
              <AddIcon />
            </Fab>
          </div>
        </OptionsBar>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            paddingTop: '35px',
          }}
        >
          {isloading === true ? (
            <Loading />
          ) : (
            <>
              <SideNavigation id="navigation">{companiesLine}</SideNavigation>
              <CardsContent id="cards">{userGroupsPanel}</CardsContent>
            </>
          )}
        </div>
      </Painel>
      <Modal
        content={'usergroups'}
        userGroupProp={{
          open,
          setOpen,
          dashboardListForm: dashboards,
          companyListForm: companies,
          rolesListForm: roles,
          handleAdd,
          getUserGroups,
        }}
      ></Modal>
    </>
  );
};

export default UserGroupsPage;
