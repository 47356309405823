import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Logo = styled.div`
  height: 60px;
`;
