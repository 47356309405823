import * as React from 'react';
import { useEffect, useState } from 'react';
import { Company, Dashboard } from '../../pages/CompaniesPage';
import CompanyContent from './contents/company';
import UserGroupContent from './contents/userGroup';
import { Role, UserGroup } from '../../pages/UserGroupsPage';
import SettingsUserGroupContent from './contents/settingsUserGroup';
import InvalidTokenContent from './contents/invalidToken';
import { CardDialog } from './styles';
import PreviewUserGroupContent from './contents/previewUserGroup';

interface CompanyProps {
  open: boolean;
  setOpen: any;
  dashboardListForm: Dashboard[];
  handleCloseOptions?: any;
  handleAdd?: any;
  handleSaveEdit?: any;
  companyEdit?: Company;
}

interface UserGroupProps {
  open: boolean;
  setOpen: any;
  dashboardListForm: Dashboard[];
  companyListForm: Company[];
  rolesListForm: Role[];
  handleCloseOptions?: any;
  handleAdd?: any;
  getUserGroups?: any;
  handleSaveEdit?: any;
  handleSaveSettings?: any;
  userGroupEdit?: UserGroup;
  countUser?: any;
}

interface SettingsUserGroupProps {
  open: boolean;
  setOpenSettings: any;
  handleCloseOptions?: any;
  getUserGroups?: any;
  handleAdd?: any;
  handleSaveEdit?: any;
  handleSaveSettings: any;
  countUser?: any;
}

interface InvalidTokenProps {
  open: boolean;
}

interface ModalProps {
  content: string;
  companyProp?: CompanyProps;
  userGroupProp?: UserGroupProps;
  settingsUserGroupProp?: SettingsUserGroupProps;
  invalidTokenProp?: InvalidTokenProps;
}

const Modal: React.FC<ModalProps> = ({
  content,
  companyProp = undefined,
  userGroupProp = undefined,
  invalidTokenProp = undefined,
}) => {
  const [data, setData] = useState<any>({});

  useEffect(() => {
    if (companyProp) {
      setData(companyProp);
    } else if (userGroupProp) {
      setData(userGroupProp);
    } else if (invalidTokenProp) {
      setData(invalidTokenProp);
    }
  }, [companyProp, invalidTokenProp, userGroupProp]);

  const handleClose = (): void => {
    if (!invalidTokenProp) {
      data.setOpen(!data.open);
    }
  };

  return (
    <CardDialog open={data.open ? data.open : false} onClose={handleClose}>
      {
        {
          company: (
            <CompanyContent
              company={data.companyEdit}
              setOpen={data.setOpen}
              dashboardListForm={data.dashboardListForm}
              handleAdd={data.handleAdd}
              handleSaveEdit={data.handleSaveEdit}
              handleCloseOptions={data.handleCloseOptions}
              handleClose={handleClose}
            ></CompanyContent>
          ),
          usergroups: (
            <UserGroupContent
              usergroup={data.userGroupEdit}
              setOpen={data.setOpen}
              handleClose={handleClose}
              handleAdd={data.handleAdd}
              handleSaveEdit={data.handleSaveEdit}
              handleCloseOptions={data.handleCloseOptions}
              dashboardListForm={data.dashboardListForm}
              companyListForm={data.companyListForm}
              rolesListForm={data.rolesListForm}
              countUser={data.countUser}
            ></UserGroupContent>
          ),
          usergroupSettings: (
            <SettingsUserGroupContent
              usergroup={data.userGroupEdit}
              setOpen={data.setOpen}
              widgetsListForm={data.widgetsListForm}
              handleClose={handleClose}
              handleAdd={data.handleAdd}
              getUserGroups={data.getUserGroups}
              handleSaveEdit={data.handleSaveEdit}
              handleSaveSettings={data.handleSaveSettings}
              handleCloseOptions={data.handleCloseOptions}
              countUser={data.countUser}
            ></SettingsUserGroupContent>
          ),
          usergroupPreview: (
            <PreviewUserGroupContent
              usergroup={data.userGroupEdit}
              setOpen={data.setOpen}
              handleClose={handleClose}
            ></PreviewUserGroupContent>
          ),
          invalidToken: <InvalidTokenContent></InvalidTokenContent>,
        }[content]
      }
    </CardDialog>
  );
};

export default Modal;
