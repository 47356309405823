import * as React from 'react';
import {
  CardContainer,
  CardHeaderStyled,
  CardStyled,
  Description,
  DescriptionDiv,
  ImageDiv,
} from './styles';
import { Widget } from '../../../pages/UserGroupsPage';
import { LinkStyled } from '../../../pages/InitialPage/styles';

interface Props {
  widget: Widget;
}

const HomePageCard: React.FC<Props> = ({ widget }) => {
  return (
    <>
      <CardContainer id="cardContainer">
        <LinkStyled
          to={widget.widgetUrl}
          target={widget.type === 'external' ? '_blank' : '_self'}
          rel={widget.type === 'external' ? 'noopener noreferrer' : ''}
        >
          <CardStyled id="cardStyled">
            <CardHeaderStyled id="headerTittle">
              <span style={{ fontFamily: 'SegoeUIBold' }}>
                {widget.widgetName}
              </span>
            </CardHeaderStyled>
            {widget.widgetUrlImage && (
              <ImageDiv>
                <img
                  src={widget.widgetUrlImage}
                  style={{ maxHeight: '90%', marginTop: '2%', maxWidth: '50%' }}
                  hidden={widget.widgetUrlImage === ''}
                />
              </ImageDiv>
            )}

            <DescriptionDiv>
              <Description>{widget.description}</Description>
            </DescriptionDiv>
          </CardStyled>
        </LinkStyled>
      </CardContainer>
    </>
  );
};

export default HomePageCard;
