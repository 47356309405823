import React, { useEffect, useState } from 'react';

import { Container, MainContent, MenuAppBarDiv } from './styles';
import MenuAppBar from '../menuAppBar';
import { useAuth } from '../../context/authContext';
import backgroundImage from '../../assets/Background-HomePage.png';

interface MainProps {
  children: any;
}

// commit to publish dev

const rolesAllowedInThisPage = [2];

const MainLayout: React.FC<MainProps> = ({ children }) => {
  const authContext = useAuth();
  const [menuAppAllowed, setMenuAppAllowed] = useState<boolean>(false);

  useEffect(() => {
    let userAllowed = false;
    authContext.user.roles?.forEach(role => {
      const found = rolesAllowedInThisPage.find(roleA => roleA === role);
      if (found) {
        userAllowed = true;
      }
    });

    setMenuAppAllowed(userAllowed);
  }, [authContext.user]);

  return (
    <>
      <MenuAppBarDiv id="menuAppBar">
        <MenuAppBar menuAllowed={menuAppAllowed} />
      </MenuAppBarDiv>
      <Container id="container">
        <MainContent
          id="main-content"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            height: '100%',
            width: '100%',
            backgroundSize: '101% 101%',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: '9%',
          }}
        >
          {children}
        </MainContent>
      </Container>
    </>
  );
};

export default MainLayout;
