import React, { useCallback, useEffect, useState } from 'react';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import api from '../../services/api';
import { Dashboard } from './styles';

interface props {
  dashIds: any;
}

const SupersetDashboard: React.FC<props> = ({ dashIds }) => {
  const [hasFilters, setHasFilters] = useState<boolean>(true);
  const getGuestToken = useCallback(async () => {
    const response = await api.post(`/superset/getGuestToken`, {
      dashIds,
    });
    setHasFilters(response.data.hasFilters);
    return response.data.guestToken.token;
  }, [dashIds]);

  useEffect(() => {
    const embed = async (): Promise<void> => {
      await embedDashboard({
        id: dashIds.uuid, // given by the Superset embedding UI
        supersetDomain: process.env.REACT_APP_SUPERSET_API_URL ?? '',
        mountPoint: document.getElementById('dashboard')!, // html element in which iframe render
        fetchGuestToken: () => getGuestToken(),
        dashboardUiConfig: { filters: { expanded: hasFilters } },
      });
    };
    if (Object.keys(dashIds).length !== 0) {
      embed();
    }
  }, [dashIds, getGuestToken, hasFilters]);

  return <Dashboard id="dashboard"></Dashboard>;
};

export default SupersetDashboard;
