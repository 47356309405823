import React, { useEffect, useState } from 'react';
import api from '../../services/api';

interface props {
  chartId: string;
}

const SupersetChart: React.FC<props> = ({ chartId }) => {
  const [sliceURL, setSliceURL] = useState();
  useEffect(() => {
    api
      .get(`/superset/getChartURL/${chartId}`)
      .then((response: any) => {
        setSliceURL(response.data.result.slice.slice_url);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [chartId]);

  return (
    <div className="App">
      <div id="dashboard">
        <iframe
          width="90%"
          height="600"
          seamless
          src={`https://superset.meine-sicht.com${sliceURL}&standalone=true`}
        ></iframe>
      </div>
    </div>
  );
};

export default SupersetChart;
