import styled from 'styled-components';

export const DashboardContainer = styled('div')(() => ({
  height: '100%',
  display: 'flex',
}));

export const DashboardHeaderBox = styled('div')(() => ({
  borderBottom: 'solid 1px #F5F5F5',
  background: '#F8F8F8',
  display: 'flex',
  justifyContent: 'center',
  height: '30px',
  padding: '0.3rem',
}));

export const DashboardBodyBox = styled('div')(() => ({
  display: 'flex',
  height: '93%',
}));
