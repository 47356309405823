import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderWilkommen = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 2rem 0 2rem;
  height: 10rem;
  margin-left: 2rem;
  margin-right: 2rem;

  span {
    margin-left: 8%;
    font-family: 'SegoeUIBoldItalic';
    font-weight: 700;
    font-size: 2.6rem;
    line-height: 1.334;
    letter-spacing: 0em;
    text-align: center;
    color: white;
  }
`;

export const LinkStyled = styled(Link)`
  display: flex;
  cursor: pointer;
  text-decoration: none !important;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  align-items: center !important;
  font-size: 1rem !impprtant;
`;

export const Cards = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 5%;

  @media (max-width: 700px) {
    margin-left: 10%;
  }
`;
