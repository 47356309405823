import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGridEB } from './styles';

interface props {
  rows: any[];
  columns: any[];
}

const GridMui: React.FC<props> = ({ rows, columns }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <DataGridEB
        style={{ backgroundColor: 'white' }}
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default GridMui;
