import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 60%;
  height: auto;
`;

export const CardStyled = styled.div`
  display: flex;
  flex: 1;
  width: 150px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #5f87b742;
  border-radius: 5%;

  ${css`
    background-color: #5f87b742 !important;
  `}

  margin-right: '30px!important';
  border-radius: 10px !important;
  border-width: 0.1px;
  text-align: center;
  margin: 25px;
  flex-direction: column;
  margin: 0;
  box-sizing: border-box;
  // box-shadow: 0 0 3px #ffffff !important ;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    box-shadow: 3px 3px 10px #ffffff !important ;
    background-color: #f8f8f2;
  }
`;

export const CardHeaderStyled = styled.div`
  background-color: #ffffff41;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1vw;
  overflow-wrap: break-word;

  @media (max-width: 500px) {
    font-size: 1.5vw;
  }
`;

export const ImageDiv = styled.div`
  height: 46%;
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DescriptionDiv = styled.div`
  height: 40%;
  margin-top: 10%;
  margin-bottom: 5%;
  align-content: center;
  max-width: 93%;
  overflow-wrap: break-word;
`;

export const Description = styled.span`
  font-size: 1vw;
  color: white;
  font-family: 'SegoeUILight';

  @media (max-width: 500px) {
    font-size: 1.5vw;
  }
`;
