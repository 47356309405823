import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import InternalRoute from './routes/Route';
import DashboardPage from './pages/DashboardPage';
import { AuthProvider } from './context/authContext';
import SignIn from './pages/SignIn';
import ChartPage from './pages/ChartPage';
import CompaniesPage from './pages/CompaniesPage';
import UserGroupsPage from './pages/UserGroupsPage';
import ResetPasswordPage from './pages/SignIn/resetPassword';
import ForgotPasswordPage from './pages/SignIn/forgotPassword';
import Users from './pages/Users';
import { LoggerProvider } from './context/logContext';
import LogsPage from './pages/logsPage';
import InitialPage from './pages/InitialPage';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <LoggerProvider>
          <Routes>
            <Route
              path="/"
              element={
                <InternalRoute isPrivate={true} component={InitialPage} />
              }
            />
            <Route
              path="/dashboards"
              element={
                <InternalRoute isPrivate={true} component={DashboardPage} />
              }
            />
            <Route
              path="/login"
              element={<InternalRoute isPrivate={false} component={SignIn} />}
            />

            <Route
              path="/users"
              element={<InternalRoute isPrivate={true} component={Users} />}
            />

            <Route
              path="/logs"
              element={<InternalRoute isPrivate={true} component={LogsPage} />}
            />

            <Route path="*" element={<InternalRoute component={SignIn} />} />

            <Route
              path="/chart"
              element={<InternalRoute isPrivate={true} component={ChartPage} />}
            />

            <Route
              path="/companies"
              element={
                <InternalRoute isPrivate={true} component={CompaniesPage} />
              }
            />

            <Route
              path="/usergroups"
              element={
                <InternalRoute isPrivate={true} component={UserGroupsPage} />
              }
            />

            <Route
              path="/resetPassword/:token"
              element={
                <InternalRoute
                  isPrivate={false}
                  component={ResetPasswordPage}
                />
              }
            />

            <Route
              path="/forgotPassword"
              element={
                <InternalRoute
                  isPrivate={false}
                  component={ForgotPasswordPage}
                />
              }
            />
          </Routes>
        </LoggerProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
