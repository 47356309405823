import React from 'react';
import { useLocation } from 'react-router-dom';
import SupersetChart from '../../components/superset-chart/superset-chart';

const ChartPage: React.FC = () => {
  const location = useLocation();

  return <SupersetChart chartId={location.state.chartId}></SupersetChart>;
};

export default ChartPage;
