/* eslint-disable no-shadow */
import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import InitialPage from '../../../pages/InitialPage';
import { MainContent } from '../../mainLayout/styles';
import backgroundImage from '../../../assets/Background-HomePage.png';

interface props {
  setOpen: any;
  handleClose: any;
  usergroup?: any;
}
const PreviewUserGroupContent: React.FC<props> = ({
  setOpen,
  handleClose,
  usergroup = undefined,
}) => {
  const [userGroupNameForm, setUserGroupNameForm] = useState<string>('');

  const handleCancel = useCallback((): void => {
    setUserGroupNameForm('');
    setOpen(false);
    handleClose();
  }, [handleClose, setOpen]);

  useEffect(() => {
    if (usergroup) setUserGroupNameForm(usergroup.name);
  }, [usergroup]);

  return (
    <>
      <Box
        style={{
          width: '1000px',
          height: '900px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DialogTitle>{`User Group Preview: ${userGroupNameForm}`}</DialogTitle>
        <DialogContent>
          <MainContent
            id="main-content"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              height: '100%',
              width: '100%',
              backgroundSize: '101% 101%',
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: '9%',
            }}
          >
            {<InitialPage widgets={usergroup.settings.widgets}></InitialPage>}
          </MainContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
        </DialogActions>
      </Box>
    </>
  );
};

export default PreviewUserGroupContent;
