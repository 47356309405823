import styled from 'styled-components';

export const Container = styled.div`
  border: solid 0.5px #cfcfcf;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Widgets = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
`;

export const WidgetsDetails = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;
