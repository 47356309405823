import styled from 'styled-components';

export const Dashboard = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  & iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
`;
