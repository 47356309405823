import styled from 'styled-components';

export const Painel = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

export const SideNavigation = styled('div')(() => ({
  height: '100%',
  width: '27%',
}));

export const CardsContent = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  flexWrap: 'wrap',
  width: '73%',
}));

export const Line = styled('hr')(() => ({
  width: '0px',
  height: '100%',
  border: 'none',
  borderLeft: '2px dotted blue',
}));
