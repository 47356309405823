import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Content } from './styles';

interface Props {
  title: string;
  msg: string;
  handleYes: Function;
  setShow: Function;
}

const alertDialog = React.forwardRef(function Transition(
  propsA: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...propsA} />;
});

const AlertDialogSlide: React.FC<Props> = props => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (): void => {
    setOpen(false);
    props.setShow(false);
  };

  const handleYes = (): void => {
    props.handleYes();
    setOpen(false);
  };

  return (
    <Content>
      <Dialog
        open={open}
        TransitionComponent={alertDialog}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{`${props.title} `}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`${props.msg} `}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Content>
  );
};

export default AlertDialogSlide;
