/* eslint-disable no-shadow */
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Box from '@mui/material/Box';
import WidgetCard from '../cards/widgetCard';
import { fixedWidgets } from '../../utils/fixedWidgets';
import { Widget } from '../../pages/UserGroupsPage';
import { Container, Header, Widgets, WidgetsDetails } from './styles';

enum EnumWidgetType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

interface Props {
  widgetsList: Widget[];
  setWidgetsList: any;
}

const WidgetsBox: React.FC<Props> = ({ widgetsList, setWidgetsList }) => {
  const [selectedWidget, setSelectedWidget] = useState<Widget>();
  const [widgetName, setWidgetName] = useState<string>('');
  const [widgetUrl, setWidgetUrl] = useState<string>('');
  const [widgetUrlImage, setWidgetUrlImage] = useState<string>('');
  const [widgetType, setWidgeType] = useState<string>(EnumWidgetType.EXTERNAL);
  const [description, setDescription] = useState<string>('');
  const [required, setRequired] = useState<any>({});
  const [showWidgetDetails, setShowWidgetDetails] = useState<boolean>(false);

  const hasRequiredFields = useCallback((form: any): boolean => {
    const requiredObj: any = {};
    if (form.widgetName.trim() === '') {
      requiredObj.widgetName = true;
    }

    if (form.widgetUrl.trim() === '') {
      requiredObj.widgetUrl = true;
    }

    if (form.internalWidget?.trim() === '') {
      requiredObj.internalWidget = true;
    }

    if (Object.keys(requiredObj).length > 0) {
      setRequired(requiredObj);
      return true;
    }
    return false;
  }, []);

  const handleAddWidget = useCallback(() => {
    const widgetForm: any = {
      widgetName,
      widgetUrl,
      widgetUrlImage,
      description,
      type: widgetType,
    };

    if (!hasRequiredFields(widgetForm)) {
      const widget = widgetsList?.find(
        (w: Widget) =>
          w.widgetUrl === widgetForm.widgetUrl &&
          w.widgetName === widgetForm.widgetName,
      );

      if (!widget) {
        setWidgetsList((prev: Widget[]) => [...prev, widgetForm]);
      }

      setWidgetName('');
      setWidgetUrl('');
      setWidgetUrlImage('');
      setDescription('');
      setRequired({});
    }
  }, [
    widgetName,
    widgetUrl,
    widgetUrlImage,
    description,
    widgetType,
    hasRequiredFields,
    widgetsList,
    setWidgetsList,
  ]);

  const createNewWidget = useCallback(() => {
    if (selectedWidget) {
      setSelectedWidget(undefined);
      setWidgetName('');
      setWidgetUrl('');
      setWidgetUrlImage('');
      setDescription('');
    }
    setShowWidgetDetails(true);
  }, [selectedWidget]);

  const handleCancel = useCallback(() => {
    setShowWidgetDetails(false);
  }, []);

  const handleEditWidget = useCallback(() => {
    if (selectedWidget) {
      const updatedWidgets = widgetsList.map((widget: Widget) => {
        if (
          widget.widgetUrl === selectedWidget.widgetUrl &&
          widget.widgetName === selectedWidget.widgetName
        ) {
          return {
            widgetName,
            widgetUrl,
            widgetUrlImage,
            description,
            widgetType,
          };
        }

        return widget;
      });

      setWidgetsList(updatedWidgets);
      setShowWidgetDetails(false);
    }
  }, [
    description,
    selectedWidget,
    setWidgetsList,
    widgetName,
    widgetType,
    widgetUrl,
    widgetUrlImage,
    widgetsList,
  ]);

  const handleDeleteWidget = useCallback(() => {
    if (selectedWidget) {
      const widgets = widgetsList.filter(
        (widget: Widget) =>
          widget.widgetName !== selectedWidget.widgetName &&
          widget.widgetUrl !== selectedWidget.widgetUrl,
      );
      setWidgetsList(widgets);
      setShowWidgetDetails(false);
    }
  }, [selectedWidget, setWidgetsList, widgetsList]);

  useEffect(() => {
    if (selectedWidget) {
      setShowWidgetDetails(true);
      setWidgetName(selectedWidget.widgetName);
      setWidgetUrl(selectedWidget.widgetUrl);
      setWidgetUrlImage(selectedWidget.widgetUrlImage);
      setWidgeType(selectedWidget.type);
      setDescription(selectedWidget.description);
    }
  }, [selectedWidget]);

  return (
    <>
      <Container>
        <Header>
          <h2 style={{ margin: '2px' }}>Widgets</h2>
          <Button
            style={{ borderRadius: '20px', fontSize: '12px' }}
            variant="contained"
            onClick={createNewWidget}
          >
            New Widget
          </Button>
        </Header>

        <Widgets>
          <Paper
            sx={{
              background: '#eff4f9',
              display: 'flex',
              gap: '20px',
              flexWrap: 'wrap',
              justifyContent: 'start',
              listStyle: 'none',
              width: '100%',
              p: 2,
            }}
            elevation={3}
          >
            {widgetsList.map((widgetObj: Widget, index: number) => {
              return (
                <WidgetCard
                  key={index}
                  widget={widgetObj}
                  setSelectedWidget={setSelectedWidget}
                ></WidgetCard>
              );
            })}
          </Paper>
        </Widgets>
        <WidgetsDetails>
          {showWidgetDetails && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div
                style={{
                  width: '94%',
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <h3
                  style={{
                    margin: 'unset',
                    marginTop: '12px',
                    marginBottom: '5px',
                    paddingLeft: '3px',
                  }}
                >
                  {selectedWidget ? selectedWidget.widgetName : 'New widget'}
                </h3>
              </div>
              <FormControl
                fullWidth
                style={{
                  width: '94%',
                  alignSelf: 'center',
                  margin: '8px 0 4px 0',
                }}
              >
                <InputLabel id="widgetTypeSelect-label">Type</InputLabel>
                <Select
                  labelId="widgetTypeSelect-label"
                  id="widgetTypeSelect"
                  name="widgetType"
                  label="Type"
                  value={widgetType}
                  onChange={event => {
                    setWidgetUrl('');
                    setWidgeType(event.target.value);
                  }}
                >
                  <MenuItem key={0} value={EnumWidgetType.INTERNAL}>
                    Internal
                  </MenuItem>
                  <MenuItem key={1} value={EnumWidgetType.EXTERNAL}>
                    External
                  </MenuItem>
                </Select>
              </FormControl>
              {widgetType === EnumWidgetType.INTERNAL && (
                <FormControl
                  fullWidth
                  style={{
                    width: '94%',
                    alignSelf: 'center',
                    margin: '8px 0 4px 0',
                  }}
                >
                  <InputLabel id="selectInternalWidget-label">
                    Internal Widget
                  </InputLabel>
                  <Select
                    error={!!required.internalWidget}
                    labelId="selectInternalWidget-label"
                    label="Internal Widget"
                    id="selectInternalWidget"
                    name="internalWidget"
                    onChange={event => {
                      setWidgetUrl(
                        JSON.parse(event.target.value as string).widgetUrl,
                      );
                      const req = required;
                      req.internalWidget = false;
                      setRequired(req);
                    }}
                  >
                    {fixedWidgets.map(internalWidget => {
                      return (
                        <MenuItem
                          key={internalWidget.widgetName}
                          value={JSON.stringify(internalWidget)}
                        >
                          {internalWidget.widgetName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <TextField
                margin="dense"
                id="widgetName"
                name="widgetName"
                value={widgetName}
                onChange={event => {
                  setWidgetName(event.target.value);
                  if (event.target.value?.trim()) {
                    const req = required;
                    req.widgetName = false;
                    setRequired(req);
                  }
                }}
                label="Widget Name"
                type="text"
                variant="outlined"
                error={!!required.widgetName}
                helperText={required.widgetName ? 'required' : ''}
                style={{ width: '94%', alignSelf: 'center' }}
              />
              <TextField
                margin="dense"
                id="widgetUrl"
                name="widgetUrl"
                value={widgetUrl}
                onChange={event => {
                  setWidgetUrl(event.target.value);
                  const req = required;
                  req.widgetUrl = false;
                  setRequired(req);
                }}
                label="URL"
                type="text"
                variant="outlined"
                error={!!required.widgetUrl}
                helperText={required.widgetUrl ? 'required' : ''}
                style={{ width: '94%', alignSelf: 'center' }}
                disabled={widgetType === EnumWidgetType.INTERNAL}
              />
              <TextField
                margin="dense"
                id="imageUrl"
                name="imageUrl"
                value={widgetUrlImage}
                onChange={event => setWidgetUrlImage(event.target.value)}
                label="Image URL"
                type="text"
                variant="outlined"
                error={!!required.imageUrl}
                helperText={required.imageUrl ? 'required' : ''}
                style={{ width: '94%', alignSelf: 'center' }}
              />
              <TextField
                margin="dense"
                id="description"
                name="description"
                value={description}
                onChange={event => setDescription(event.target.value)}
                label="Description"
                type="text"
                variant="outlined"
                error={!!required.description}
                helperText={required.description ? 'required' : ''}
                style={{ width: '94%', alignSelf: 'center' }}
              />
              {selectedWidget ? (
                <div
                  style={{
                    width: '94%',
                    height: '7%',
                    alignSelf: 'center',
                    paddingTop: '7px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <Button
                      style={{ background: '#ff3737' }}
                      onClick={handleDeleteWidget}
                      variant="contained"
                    >
                      Delete widget
                    </Button>
                  </div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Button onClick={handleCancel} variant="contained">
                      Cancel
                    </Button>
                    <Button onClick={handleEditWidget} variant="contained">
                      Save
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: '94%',
                    height: '7%',
                    alignSelf: 'center',
                    paddingTop: '7px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                  }}
                >
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Button onClick={handleCancel} variant="contained">
                      Cancel
                    </Button>
                    <Button onClick={handleAddWidget} variant="contained">
                      Add widget
                    </Button>
                  </div>
                </div>
              )}
            </Box>
          )}
        </WidgetsDetails>
      </Container>
    </>
  );
};

export default WidgetsBox;
