import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import Switch from '@mui/material/Switch';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
// import AdbIcon from '@mui/icons-material/Adb';
import { useState } from 'react';
import { useAuth } from '../../context/authContext';
import { Logo } from './styles';

interface Props {
  menuAllowed: boolean;
}

const pages = ['Home', 'Companies', 'User Groups', 'Users', 'Logs'];

const layoutDefault = {
  urlLogo:
    'https://api.meine-sicht.com/files/836654b1d642bb4672a2-1_Logo_transparenter%20Hintergrund.png',
  mainColor: 'blueviolet',
};

const MenuAppBar: React.FC<Props> = ({ menuAllowed }) => {
  const [anchorUser, setAnchorUser] = useState<null | HTMLElement>(null);
  const [anchorOptions, setAnchorOptions] = useState<null | HTMLElement>(null);

  const { signOut, user } = useAuth();
  const navigate = useNavigate();

  const handleUser = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorUser(event.currentTarget);
  };

  const handleOptions = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorOptions(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorOptions(null);
    setAnchorUser(null);
  };

  const handleApply = (page: any): void => {
    handleClose();
    switch (page) {
      case 'Home':
        navigate('/');
        break;
      case 'Companies':
        navigate('/companies');
        break;
      case 'User Groups':
        navigate('/usergroups');
        break;
      case 'Users':
        navigate('/users');
        break;
      case 'Logs':
        navigate('/logs');
        break;
      default:
        navigate('/');
        break;
    }
  };

  const handleLogOut = (): void => {
    signOut();
    navigate('/login');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{
          display: 'flex',
          marginBottom: '1px',

          background:
            user.mainColor && user.mainColor !== ''
              ? user.mainColor
              : layoutDefault.mainColor,

          boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px 0px',
        }}
      >
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <div id="options" style={{ display: 'flex' }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleOptions}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              sx={{ mt: '35px', left: '10px' }}
              id="menu-appbar"
              anchorEl={anchorOptions}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorOptions)}
              onClose={handleClose}
            >
              {menuAllowed ? (
                pages.map(page => (
                  <MenuItem key={page} onClick={() => handleApply(page)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))
              ) : (
                <MenuItem onClick={() => handleApply('Home')}>
                  <Typography textAlign="center">{'Home'}</Typography>
                </MenuItem>
              )}
            </Menu>
          </div>

          <Logo>
            <a href="/">
              <img
                src={
                  user.urlLogo && user.urlLogo !== ''
                    ? user.urlLogo
                    : layoutDefault.urlLogo
                }
                alt="Logo"
                style={{ height: '60px' }}
              />
            </a>
          </Logo>
          <div id="userAvatar" style={{ display: 'flex' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleUser}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              sx={{ mt: '35px', left: '-25px' }}
              id="menu-appbar"
              anchorEl={anchorUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorUser)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogOut}>LogOut</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MenuAppBar;
