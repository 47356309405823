/* eslint-disable no-shadow */
import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { Widget } from '../../../pages/UserGroupsPage';
import WidgetsBox from '../../widgetsBox';

interface props {
  setOpen: any;
  widgetsListForm: Widget[];
  handleCloseOptions?: any;
  handleAdd?: any;
  getUserGroups?: any;
  handleSaveEdit?: any;
  handleSaveSettings?: any;
  usergroup?: any;
  handleClose: any;
  countUser: any;
}
const SettingsUserGroupContent: React.FC<props> = ({
  setOpen,
  handleCloseOptions = undefined,
  handleClose,
  handleSaveSettings,
  usergroup = undefined,
}) => {
  const [userGroupNameForm, setUserGroupNameForm] = useState<string>('');
  const [videoLinkForm, setVideoLinkForm] = useState<string>('');

  const [externalWidgetsList, setExternalWidgetsList] = useState<Widget[]>([]);
  // const [widgetsToDelete, setWidgetsToDelete] = useState<string[]>([]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formdata = new FormData(event.currentTarget);
      const settings = {
        videoLink: formdata?.get('videoLink')?.toString() ?? '',
        widgets: externalWidgetsList,
      };

      const userGroupToUpdate = { ...usergroup, settings };

      handleSaveSettings(userGroupToUpdate);

      setOpen(false);
      handleCloseOptions();
    },
    [
      externalWidgetsList,
      handleCloseOptions,
      handleSaveSettings,
      setOpen,
      usergroup,
    ],
  );

  const handleCancel = useCallback((): void => {
    setUserGroupNameForm('');

    setUserGroupNameForm('');

    setExternalWidgetsList([]);
    setOpen(false);
    if (usergroup) {
      handleCloseOptions();
    }
    handleClose();
  }, [handleClose, handleCloseOptions, setOpen, usergroup]);

  useEffect(() => {
    if (usergroup) {
      setUserGroupNameForm(usergroup.name);
      setVideoLinkForm(usergroup?.settings?.videoLink);
      setExternalWidgetsList(usergroup?.settings?.widgets ?? []);
    }
  }, [usergroup]);

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} style={{ width: '700px' }}>
        <DialogTitle>{`User Group Settings: ${userGroupNameForm}`}</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            margin="dense"
            id="userGroupName"
            name="userGroupName"
            value={userGroupNameForm}
            onChange={event => setUserGroupNameForm(event.target.value)}
            label="User Group Name"
            type="text"
            variant="outlined"
            disabled={true}
            style={{ marginBottom: '20px' }}
          />

          <FormControl style={{ marginBottom: '20px' }}>
            <TextField
              margin="dense"
              id="videoLink"
              name="videoLink"
              value={videoLinkForm}
              onChange={event => setVideoLinkForm(event.target.value)}
              label="Video (link to video shown at initial page)"
              type="text"
              variant="outlined"
            />
          </FormControl>
          <WidgetsBox
            widgetsList={externalWidgetsList}
            setWidgetsList={setExternalWidgetsList}
          ></WidgetsBox>
          {/* <AddWigetsBox
            widgetsList={externalWidgetsList}
            setWidgetsList={setExternalWidgetsList}
            widgetsToDelete={widgetsToDelete}
            setWidgetsToDelete={setWidgetsToDelete}
            isEdit={!!usergroup}
          ></AddWigetsBox> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="submit">{usergroup ? 'Save All' : 'Add'}</Button>
        </DialogActions>
      </Box>
    </>
  );
};

export default SettingsUserGroupContent;
