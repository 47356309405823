import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

export const Content = styled.div``;

export const DataGridEB = styled(DataGrid)`
  background-color: white;

  &
    .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaderCheckbox {
    outline: none;
  }

  &
    .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader--sorted.MuiDataGrid-withBorderColor {
    outline: none;
  }

  &
    .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor {
    outline: none;
  }
`;
