import styled from 'styled-components';

export const Container = styled.div`
  margin: 0.5rem 1rem;
`;

export const Content = styled.div`
  display: flex;
  align-content: center;
`;
