import * as React from 'react';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const InvalidTokenContent: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    navigate('/forgotPassword');
  }, [navigate]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          margin: '20px',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <WarningRoundedIcon
          style={{ width: '50px', height: '50px', color: 'orange' }}
        />
        <h2>Invalid Token</h2>
        <h4 style={{ marginBottom: 0, marginTop: 0, color: 'dimgray' }}>
          Your session time expired.
        </h4>
        <h4 style={{ marginTop: 0, color: 'dimgray' }}>
          Click on forgot password below to recieve new email
        </h4>
        <Button
          style={{ width: '100%' }}
          variant="contained"
          onClick={handleSubmit}
        >
          forgot password
        </Button>
      </div>
    </>
  );
};

export default InvalidTokenContent;
