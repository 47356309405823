export const fixedWidgets = [
  {
    widgetName: 'Dashboards',
    widgetUrl: '/dashboards',
    type: 'internal',
  },
  {
    widgetName: 'Storytelling',
    widgetUrl: '/storytelling',
    type: 'internal',
  },
  {
    widgetName: 'Charts',
    widgetUrl: '/charts',
    type: 'internal',
  },
];
