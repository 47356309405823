import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CardActions, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Edit as EditIcon,
  Settings as SettingsIcon,
  ExpandMore as ExpandMoreIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import Modal from '../../modal';
import { Role, UserGroup } from '../../../pages/UserGroupsPage';
import { Company, Dashboard } from '../../../pages/CompaniesPage';
import AlertDialogSlide from '../../alertDialog';

interface UserGroupProps {
  userGroup: UserGroup;
  dashboardListForm: Dashboard[];
  companyListForm: Company[];
  rolesListForm: Role[];
  handleDelete: any;
  handleSaveEdit: any;
  handleSaveSettings: any;
  countUser: any;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((expandProps: ExpandMoreProps) => {
  const { ...other } = expandProps;
  const { expand } = expandProps;
  const iconButtonProps = { ...other, expand: expand.toString() };
  return <IconButton {...iconButtonProps} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const UserGroupCard: React.FC<UserGroupProps> = ({
  userGroup,
  dashboardListForm,
  companyListForm,
  rolesListForm,
  handleDelete,
  handleSaveEdit,
  handleSaveSettings,
  countUser,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [anchorOptions, setAnchorOptions] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);

  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  const handleOptions = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorOptions(event.currentTarget);
  };

  const handleCloseOptions = (): void => {
    setAnchorOptions(null);
  };

  const openModalEdit = (): void => {
    setOpen(true);
  };

  const openModalSettings = (): void => {
    setOpenSettings(true);
  };

  const openModalPreview = (): void => {
    setOpenPreview(true);
  };

  const handleClickDelete = (): void => {
    setOpenAlertDialog(true);
  };

  const handleDeletion = (): void => {
    handleDelete({ userGroup, handleCloseOptions });
    setOpenAlertDialog(false);
  };

  return (
    <>
      {openAlertDialog && (
        <AlertDialogSlide
          title={'Delete user group'}
          msg={'Are you sure you want to delete this user group?'}
          handleYes={handleDeletion}
          setShow={setOpenAlertDialog}
        />
      )}

      <Card
        sx={{
          maxWidth: 300,
          background: '#ECF3FB',
          marginLeft: '30px',
          width: 300,
          borderRadius: '20px',
        }}
      >
        <CardHeader
          title={userGroup.name}
          subheader={
            companyListForm.find(
              (x: any) => x.companyId === userGroup.companyId,
            )?.name
          }
          action={
            <>
              <IconButton onClick={handleOptions} aria-label="settings">
                <MoreVertIcon />
              </IconButton>
              <Menu
                sx={{ mt: '35px', left: '10px' }}
                id="menu-appbar"
                anchorEl={anchorOptions}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorOptions)}
                onClose={handleCloseOptions}
              >
                <MenuItem
                  style={{ paddingLeft: '8px' }}
                  onClick={openModalEdit}
                >
                  <EditIcon />
                  <Typography
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '1px',
                    }}
                    textAlign="center"
                  >
                    Edit
                  </Typography>
                </MenuItem>
                <MenuItem
                  style={{ paddingLeft: '8px' }}
                  onClick={openModalSettings}
                >
                  <SettingsIcon />
                  <Typography
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '1px',
                    }}
                    textAlign="center"
                  >
                    Settings
                  </Typography>
                </MenuItem>

                <MenuItem
                  style={{ paddingLeft: '8px' }}
                  onClick={openModalPreview}
                >
                  <VisibilityIcon />
                  <Typography
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '1px',
                    }}
                    textAlign="center"
                  >
                    Preview
                  </Typography>
                </MenuItem>

                <MenuItem
                  style={{ paddingLeft: '8px' }}
                  onClick={handleClickDelete}
                >
                  <DeleteForeverIcon style={{ top: '10px' }} />
                  <Typography
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '2px',
                    }}
                    textAlign="center"
                  >
                    Delete
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          }
        />
        {!expanded && <CardContent> Dashboards</CardContent>}
        <CardActions disableSpacing>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {' '}
            {userGroup.moreInformation.map((x: any, index: number) => {
              return (
                <Typography key={index} paragraph>
                  {x}
                </Typography>
              );
            })}
          </CardContent>
        </Collapse>
      </Card>
      <Modal
        content={'usergroups'}
        userGroupProp={{
          open,
          setOpen,
          dashboardListForm,
          companyListForm,
          rolesListForm,
          handleSaveEdit,
          userGroupEdit: userGroup,
          handleCloseOptions,
          countUser,
        }}
      ></Modal>
      <Modal
        content={'usergroupSettings'}
        userGroupProp={{
          open: openSettings,
          setOpen: setOpenSettings,
          dashboardListForm,
          companyListForm,
          rolesListForm,
          handleSaveSettings,
          handleSaveEdit,
          userGroupEdit: userGroup,
          handleCloseOptions,
          countUser,
        }}
      ></Modal>

      <Modal
        content={'usergroupPreview'}
        userGroupProp={{
          open: openPreview,
          setOpen: setOpenPreview,
          dashboardListForm,
          companyListForm,
          rolesListForm,
          handleSaveSettings,
          handleSaveEdit,
          userGroupEdit: userGroup,
          handleCloseOptions,
          countUser,
        }}
      ></Modal>
    </>
  );
};

export default UserGroupCard;
