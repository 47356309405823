import React, { useCallback, useEffect } from 'react';
import { RadialMenu } from './radialMenu.js';
import { Widget } from '../../pages/UserGroupsPage/index.js';

interface props {
  widgets: Widget[];
  setSelectedWidget: any;
}

const RadialMenuReact: React.FC<props> = ({ widgets, setSelectedWidget }) => {
  const handleClick = useCallback(
    (event: any) => {
      const clickedSector = event.title;
      const selectedWidget = widgets.find(
        (widget: Widget) => widget.widgetName === clickedSector,
      );
      setSelectedWidget(selectedWidget);
    },
    [setSelectedWidget, widgets],
  );

  const handleClose = useCallback(() => {
    setSelectedWidget(undefined);
  }, [setSelectedWidget]);

  useEffect(() => {
    const menuItems = widgets.map((widget: Widget) => {
      return {
        id: widget.widgetName,
        title: widget.widgetName,
        image: widget.widgetUrlImage,
      };
    });

    if (widgets.length > 0) {
      const radialMenu = document.getElementById('radialMenu');
      if (radialMenu) {
        const svgMenu = new RadialMenu({
          parent: radialMenu,
          size: 800,
          closeOnClick: true,
          menuItems,
          onClick: handleClick,
          onClose: handleClose,
        });
        svgMenu.open();
      }
    }
  }, [handleClick, handleClose, widgets]);

  return <div id="radialMenu" style={{ width: '50%' }}></div>;
};

export default RadialMenuReact;
