import React from 'react';
import {
  PathRouteProps as ReactDOMRouteProps,
  Navigate,
} from 'react-router-dom';
import MainLayout from '../components/mainLayout';
import { useAuth } from '../context/authContext';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const InternalRoute: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
}) => {
  const { user } = useAuth();

  return isPrivate === !!user ? (
    isPrivate ? (
      <MainLayout>
        <Component />
      </MainLayout>
    ) : (
      <Component />
    )
  ) : (
    <Navigate to={isPrivate ? '/login' : '/'} />
  );
};

export default InternalRoute;
