import styled from 'styled-components';

export const OptionsBar = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '15px',
}));

export const Cards = styled('div')(() => ({
  display: 'flex',
  marginTop: '20px',
  flexWrap: 'wrap',
  marginLeft: '24px',
}));
